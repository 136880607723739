<template>
  <div>
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="domain-info mb-8">
          <!-- Domain -->
          <select-filter
            labelname="Domain"
            :options="domainOptions"
            optionsName="domainName"
            :value="domainSelected"
            @input="
              newDomain => {
                domainSelected = newDomain;
              }
            "
          ></select-filter>
          <!-- Date Picker -->
          <date-range-picker
            :fromDateValue="fromDate"
            :toDateValue="toDate"
            @inputFromDate="
              newFromDate => {
                fromDate = newFromDate;
              }
            "
            @inputToDate="
              newToDate => {
                toDate = newToDate;
              }
            "
          ></date-range-picker>
          <!-- Only Companies checkbox -->
          <only-company
            :onlyCompaniesValue="onlyCompanies"
            @input="
              newonlyCompanies => {
                onlyCompanies = newonlyCompanies;
              }
            "
          ></only-company>
          <!-- Update Dashboard -->
          <vs-button
            type="filled"
            @click="updateData()"
            class="small-btn bg-actionbutton"
            >{{ LabelConstant.buttonLabelUpdateDashboard }}</vs-button
          >
        </div>
      </div>
    </div>        
      <!-- Identified Companies -->    
      <div class="vx-row">
        <div class="vx-col xl:w-1/4  mb-8  md:w-1/2 w-full lg-cus-6" id="v-step-0">        
            <single-card
              icon="BriefcaseIcon"
              smallTitle="Identified Companies"
              :totalValue="identifiedCompanies.StatEndValue"
              :bottomText="identifiedCompanies.StatStartValue + ' previous period'"
              :upDownValue="identifiedCompanies.StatChangePercent"
              bgcolor="bg-first-gradient"
              maintextcolor="text-first"
              bgimg="beg.png"
              viewAllLink="Search Lead"
            ></single-card>        
        </div>
        <!-- Email Visitors -->
        <div class="vx-col xl:w-1/4  mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-1">        
            <single-card
              icon="MailIcon"
              smallTitle="Email Visitors"
              :totalValue="emailVisitors.StatEndValue"
              :bottomText="emailVisitors.StatStartValue + ' previous period'"
              :upDownValue="emailVisitors.StatChangePercent"
              Upicon="ArrowDownIcon"
              textcolor="text-danger"
              bgcolor="bg-second-gradient"
              maintextcolor="text-second"
              bgimg="mail.png"
              viewAllLink="Email Campaigns"
            >
            </single-card>        
        </div>
        <!-- Form Capture Visitors -->
        <div class="vx-col xl:w-1/4  mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-2">
          <single-card
            icon="ListIcon"
            smallTitle="Form Capture Visitors"
            :totalValue="formCaptureVisitors.StatEndValue"
            :bottomText="formCaptureVisitors.StatStartValue + ' previous period'"
            :upDownValue="formCaptureVisitors.StatChangePercent"
            Upicon="ArrowDownIcon"
            textcolor="text-danger"
            bgcolor="bg-third-gradient"
            maintextcolor="text-third"
            bgimg="list.png"
            viewAllLink="Form Captures"
          >
          </single-card>
        </div>
        <!-- Page Views -->
        <div class="vx-col xl:w-1/4  mb-8 md:w-1/2 w-full lg-cus-6">
          <single-card
            icon="EyeIcon"
            smallTitle="Page Views"
            :totalValue="pageViews.StatEndValue"
            :bottomText="pageViews.StatStartValue + ' previous period'"
            :upDownValue="pageViews.StatChangePercent"
            Upicon="ArrowDownIcon"
            textcolor="text-danger"
            bgcolor="bg-fourth-gradient"
            maintextcolor="text-fourth"
            bgimg="eye.png"
            viewAllLink="Overview"
          >
          </single-card>
        </div>
      
      </div>      
    <div class="vx-row">
      <!-- Latest Visitor -->
      <div class="vx-col xl:w-1/4 col-cus-12 mb-8 w-full">
        <vx-card class="main-list h-full">
          <div class="card-title">
            <h2>Latest Visitors</h2>
            <!-- <vs-button
              @click="popupActive = true"
              color="gray"
              type="flat"
              icon-pack="feather"
              icon="icon-help-circle"
              class="p-0 help-btn"
            ></vs-button> -->
          </div>
          <ul class="latest-list">
            <li
              v-for="(latestVisitor, index) in latestVisitors"
              :key="index"
              v-if="latestVisitors && latestVisitors.length > 0 && index < limitedLatestVisitors"
            >
              <a
                @click="
                  (detailspopupActive = true),
                    getCompanyDetails(latestVisitor.Visitor_Company_Id)
                "
                >{{ latestVisitor.Visitor_Company_Name }}</a
              ><span class="date">{{ latestVisitor.Date_Created }}</span
              ><a
                class="go-link text-actionbutton"
                @click="
                  (detailspopupActive = true),
                    getCompanyDetails(latestVisitor.Visitor_Company_Id)
                "
                ><feather-icon icon="EyeIcon"></feather-icon
              ></a>
            </li>
            <p v-if="latestVisitors.length < 1">
              No visit history for this date range.
            </p>
          </ul>
          <vs-button
            class="small-btn bg-actionbutton"
            type="filled"
            icon-pack="feather"
            icon="icon-eye"
            v-if="latestVisitors.length > 0 && limitedLatestVisitors == 5"
            @click="showMoreVisitor()"
            >Show More</vs-button
          >
          <vs-button
            class="small-btn bg-actionbutton"
            type="filled"
            icon-pack="feather"
            icon="icon-eye"
            v-if="latestVisitors.length > 0 && limitedLatestVisitors > 5"
            @click="showMoreVisitor()"
            >Show Less</vs-button
          >
        </vx-card>
      </div>
      <!-- Site Traffic Overview -->
      <div class="vx-col xl:w-3/4 col-cus-12 mb-8 w-full">
        <three-xaxis-chart
          :classOptional="'more-card-title'"
          :isLink="{ name: 'Overview', params: { uid: uid } }"
          :linkLabel="'View All'"
          :linkClass="'view-option text-actionbutton'"
          :chartTitle="'Site Traffic Overview'"
          :chartType="'line'"
          :chartHeight="'400'"

          :seriesNameOne="'Companies Identified'"
          :seriesDataOne="siteTrafficOverviewChartSeriesDataOne"
          
          :seriesNameTwo="'Unique Visitors'"
          :seriesDataTwo="siteTrafficOverviewChartSeriesDataTwo"

          :seriesNameThree="'Page Views'"
          :seriesDataThree="siteTrafficOverviewChartSeriesDataThree"
          
          :optionsXaxis="siteTrafficOverviewChartOptionsXaxis"
        ></three-xaxis-chart>
      </div>
    </div>
    <div class="vx-row">
      <!-- Latest Keywords -->
      <div class="vx-col xl:w-1/4 lg-cus-6 mb-8 w-full">
        <vx-card class="main-list h-full">
          <div class="card-title more-card-title">
            <router-link
              class="view-option text-actionbutton"
              :to="{ name: 'Referrer', params: { uid: uid } }"
              >View All</router-link
            >
            <h2>Latest Keywords</h2>
          </div>
          <ul class="latest-list">
            <li
              v-for="(latestKeyword, index) in latestKeywords"
              :key="index"
              v-if="latestKeywords && latestKeywords.length > 0 && index < limitedLatestKeywords"
            >
              <router-link
                :to="{
                  path: '/leads/search-lead/uid/' + uid,
                  query: {
                    zone: domainSelected.domainName,
                    dateStart: fromDate,
                    dateEnd: toDate,
                    onlyCompanies: onlyCompanies,
                    keyword: latestKeyword.Value
                  }
                }"
                >{{ latestKeyword.Value }}</router-link
              ><span class="date">{{ latestKeyword.Key }}</span
              ><router-link
                target="_blank"
                class="go-link text-actionbutton"
                :to="{
                  path: '/leads/search-lead/uid/' + uid,
                  query: {
                    zone: domainSelected.domainName,
                    dateStart: fromDate,
                    dateEnd: toDate,
                    onlyCompanies: onlyCompanies,
                    keyword: latestKeyword.Value
                  }
                }"
                ><feather-icon icon="EyeIcon"></feather-icon
              ></router-link>
            </li>
            <p v-if="latestKeywords.length < 1">
              No search terms for this date range.
            </p>
          </ul>
          <vs-button
            class="small-btn bg-actionbutton"
            type="filled"
            icon-pack="feather"
            icon="icon-eye"
            v-if="latestKeywords.length > 0 && limitedLatestKeywords == 5"
            @click="showMoreKeywords()"
            >Show More</vs-button
          >
          <vs-button
            class="small-btn bg-actionbutton"
            type="filled"
            icon-pack="feather"
            icon="icon-eye"
            v-if="latestKeywords.length > 0 && limitedLatestKeywords > 5"
            @click="showMoreKeywords()"
            >Show Less</vs-button
          >
        </vx-card>
      </div>
      <!-- Recent Visits -->
      <div class="vx-col xl:w-5/12 lg-cus-6 mb-8 w-full">
        <vx-card class="h-full">
          <div class="card-title more-card-title">
            <router-link
              class="view-option text-actionbutton"
              :to="{ name: 'Location', params: { uid: uid } }"
              >View All</router-link
            >
            <h2>Recent Visits</h2>
          </div>
          <GmapMap
            :center="center"
            :zoom="2"
            map-type-id="terrain"
            style="height: 416px;"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :title="m.infoText"
              :clickable="true"
            />
          </GmapMap>
        </vx-card>
      </div>
      <!-- Most Active Visiting Companies -->
      <div class="vx-col xl:w-4/12 col-cus-12  mb-8 w-full">
        <single-type-horizontal-multi-column-chart
          :chartTitle="'Most Active Visiting Companies'"
          :chartType="'bar'"
          :chartHeight="'400'"

          :seriesNameOne="'Views'"
          :seriesDataOne="mostActiveVisitingCompaniesBarChartSeriesDataOne"

          :optionsXaxis="mostActiveVisitingCompaniesBarChartOptionsXaxis"
        ></single-type-horizontal-multi-column-chart>
        <!-- <vx-card class="h-full">
          <div class="card-title">
            <h2>Most Active Visiting Companies</h2>
          </div>
          <vue-apex-charts
            type="bar"
            height="400"
            :options="mostActiveVisitingCompaniesBarChart.chartOptions"
            :series="mostActiveVisitingCompaniesBarChart.series"
          ></vue-apex-charts>
        </vx-card> -->
      </div>
    </div>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
    <!-- Company Details popup -->
    <company-details-modal
      :latestVisitorId="latestVisitorId"
      :searchLeadDetailsData="searchLeadDetails"
      @inputSearchLeadDetails="
        newSearchLeadDetails => {
          searchLeadDetails = newSearchLeadDetails;
        }
      "
      :pageViewsData="pageViewsCompany"
      @inputPageViews="
        newpageViewsCompany => {
          pageViewsCompany = newpageViewsCompany;
        }
      "
      :keywordsData="keywords"
      @inputKeywords="
        newKeywords => {
          keywords = newKeywords;
        }
      "
      :allVisitorUniqueDetailShortData="allVisitorUniqueDetailShort"
      @inputAllVisitorUniqueDetailShort="
        newAllVisitorUniqueDetailShort => {
          allVisitorUniqueDetailShort = newAllVisitorUniqueDetailShort;
        }
      "
      :detailspopupActive="detailspopupActive"
      @close="closepopup"
      @getCompanyDetails="getCompanyDetails(latestVisitorId)"
    >
    </company-details-modal>
    <!-- Thank You -->
    <vs-popup
      class="tag-info close-open"
      title="Free Trial Expired"
      :active.sync="thankyouActive"
      >
      <vs-button
        @click="thankyouActive = false"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button
      >
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div class="popup-icon">
              <img src="../../../src/assets/images/thankyou.svg" />
            </div>
            <p>
              Thank You for trying Visual Visitor. You have reached the end of your free trial. We hope you have enjoyed the information we have provided. To sign up and continue enjoying the benefits of Visual Visitor, simply click the sign up button below. Your account will continue where it left off, no need to reinstall.
              <span class="thanks-msg">Thank You,</span>
              <span class="thanks-msg">The Visual Visitor Team</span>
            </p>
          </div>
        </div>
      </template>
      <div class="float-right mt-6">
        <vs-button
          @click="redirectToSignUp()"
          color="primary"
          type="filled"
          class="small-btn mb-6"
          >Sign Up Now</vs-button
        >
      </div>
    </vs-popup>
   
  </div>
</template>

<script>
//import Vue from 'vue'
import VxCard from "@/components/vx-card/VxCard";
//import Cookie from "js-cookie";
//import store from '../../store/store'

/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import OnlyCompany from "../../components/filter/OnlyCompany";
import HelpModal from "../../components/modal/HelpModal";
import CompanyDetailsModal from "../../components/modal/CompanyDetailsModal";
import SingleCard from "../../components/SingleCard";
import VueJwtDecode from "vue-jwt-decode";


/* chart */
import ThreeXaxisChart from '../../components/charts/ThreeXaxisChart.vue';
import SingleTypeHorizontalMultiColumnChart from '../../components/charts/SingleTypeHorizontalMultiColumnChart.vue';

export default {
  title: "Dashboard",
  components: {
    VxCard,
    SelectFilter,
    DateRangePicker,
    OnlyCompany,
    HelpModal,
    CompanyDetailsModal,
    SingleCard,
    ThreeXaxisChart,
    SingleTypeHorizontalMultiColumnChart,
    VueJwtDecode
  },
  data() {
    return {
      uid: this.$route.params.id,
      //Thank you popup
      isThankYouForSignOut: false,
      thankyouActive: false,
      isSignUpButton: false,
      initialRole: null,

      //Help popup
      popupActive: false,

      /* Filter Select */
      domainSelected: { domainName: "All" },
      //Domain dropdown options
      domainOptions: [],

      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      //Only Companies
      onlyCompanies: true,

      //Identified Companies data
      identifiedCompanies: {
        StatName: null,
        StatChangePercent: 0,
        StatEndValue: 0,
        StatStartValue: 0
      },

      //Email Visitors data
      emailVisitors: {
        StatName: null,
        StatChangePercent: 0,
        StatEndValue: 0,
        StatStartValue: 0
      },

      //Form Capture Visitors data
      formCaptureVisitors: {
        StatName: null,
        StatChangePercent: 0,
        StatEndValue: 0,
        StatStartValue: 0
      },

      //Page Views data
      pageViews: {
        StatName: null,
        StatChangePercent: 0,
        StatEndValue: 0,
        StatStartValue: 0
      },

      //Store latest visitor data
      latestVisitors: [],
      //Initial latest visitor limited to 5 records.
      limitedLatestVisitors: 5,

      //Company details popup
      detailspopupActive: false,
      latestVisitorId: "",
      searchLeadDetails: {},
      allVisitorUniqueDetailShort: [],
      pageViewsCompany: {},
      keywords: [],

      /* Site Traffic Overview Chart */
      siteTrafficOverviewChartSeriesDataOne: [],
      siteTrafficOverviewChartSeriesDataTwo: [],
      siteTrafficOverviewChartSeriesDataThree: [],
      siteTrafficOverviewChartOptionsXaxis: [],

      //Store latest keyword data
      latestKeywords: [],
      //Initial latest keyword limited to 5 records.
      limitedLatestKeywords: 5,

      //Recent Visits Map
      center: { lat: 41, lng: -87 },
      //Store details for Map
      markers: [],

      /* Most Active Visiting Companies Bar Chart */
      mostActiveVisitingCompaniesBarChartSeriesDataOne: [],
      mostActiveVisitingCompaniesBarChartOptionsXaxis: [],
      // mostActiveVisitingCompaniesBarChart: {
      //   series: [
      //     {
      //       name: "Views",
      //       data: [0]
      //     }
      //   ],
      //   chartOptions: {
      //     colors: [token[uid].themeDetail.tempChartColor1],
      //     plotOptions: {
      //       bar: {
      //         horizontal: true,
      //         endingShape: "rounded"
      //       }
      //     },
      //     dataLabels: {
      //       enabled: false
      //     },
      //     xaxis: {
      //       categories: []
      //     }
      //   }
      // }
    };
  },
  mounted() {
    let uid = this.$route.params.id;
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    let tokenActual = token[uid].token
    let jwtDecode = VueJwtDecode.decode(tokenActual);
    this.initialRole = jwtDecode.initialRole;
    
    
    if(this.initialRole == "Global Admin" || this.initialRole == 'Reseller Admin')
    {
      this.thankyouActive = false;
    }else{
      if(token[uid].isExpiredFreeTrial == true && this.initialRole != "Global Admin" && token[uid].isActiveFreeTrial == true)
      {
        this.thankyouActive = true;
      }
    }
  },
  created() {
    if (this.$route.params.reload) {
      location.reload();
    }
    this.setFromDate();
    this.setToDate();

    this.getZoneListForFilter();
    this.getCompaniesIdentifiedCount();
    this.getEmailVisitorCount();
    this.getFormVisitorCount();
    this.getPageViewCount();
    this.getLastestVisitors();
    this.getPageViewsUniqueVisitorsCompaniesIdentifiedByDay();
    this.getLastestKeywords();
    this.getTopCompanies();
  },
  methods: {
    /* Popup Close */
    closepopup() {
      (this.popupActive = false), (this.detailspopupActive = false);
    },
    //On clicking Update Dashboard button will update all data
    updateData() {
      this.getCompaniesIdentifiedCount();
      this.getEmailVisitorCount();
      this.getFormVisitorCount();
      this.getPageViewCount();
      this.getLastestVisitors();
      this.getPageViewsUniqueVisitorsCompaniesIdentifiedByDay();
      this.getLastestKeywords();
      this.getTopCompanies();
    },
    //For latest visitors 'show more' and 'show less' toggle
    showMoreVisitor() {
      if (this.limitedLatestVisitors == 5) {
        this.limitedLatestVisitors = this.latestVisitors.length;
      } else {
        this.limitedLatestVisitors = 5;
      }
    },
    //For latest keywords 'show more' and 'show less' toggle
    showMoreKeywords() {
      if (this.limitedLatestKeywords == 5) {
        this.limitedLatestKeywords = this.latestKeywords.length;
      } else {
        this.limitedLatestKeywords = 5;
      }
    },
    //Get latest visitor company details
    getCompanyDetails(id) {
      this.latestVisitorId = id;
      this.getSearchLeadDetail(id);
      this.getAllVisitorUniqueDetailShort(id);
      this.getPageViews(id);
      this.getKeywords(id);
    },

    //Get Domain
    getZoneListForFilter() {
      this.$vs.loading();
      this.axios
        .get("/ws/Zone/GetZoneListForFilter")
        .then(response => {
          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            this.domainOptions.push({
              domainName: data[i]
            });
          }

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Identified Companies
    getCompaniesIdentifiedCount() {
      let getCompaniesIdentifiedCountParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/Dashboard/GetCompaniesIdentifiedCount",
          getCompaniesIdentifiedCountParams
        )
        .then(response => {
          let data = response.data;

          this.identifiedCompanies.StatName = data.StatName;
          this.identifiedCompanies.StatChangePercent = data.StatChangePercent;
          this.identifiedCompanies.StatEndValue = this.numberFormatter(
            data.StatEndValue
          );
          this.identifiedCompanies.StatStartValue = this.numberFormatter(
            data.StatStartValue
          );
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Email Visitors
    getEmailVisitorCount() {
      let getEmailVisitorCountParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post("/ws/Dashboard/GetEmailVisitorCount", getEmailVisitorCountParams)
        .then(response => {
          let data = response.data;

          this.emailVisitors.StatName = data.StatName;
          this.emailVisitors.StatChangePercent = data.StatChangePercent;
          this.emailVisitors.StatEndValue = this.numberFormatter(
            data.StatEndValue
          );
          this.emailVisitors.StatStartValue = this.numberFormatter(
            data.StatStartValue
          );
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Form Capture Visitors
    getFormVisitorCount() {
      let getFormVisitorCountParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post("/ws/Dashboard/GetFormVisitorCount", getFormVisitorCountParams)
        .then(response => {
          let data = response.data;

          this.formCaptureVisitors.StatName = data.StatName;
          this.formCaptureVisitors.StatChangePercent = data.StatChangePercent;
          this.formCaptureVisitors.StatEndValue = this.numberFormatter(
            data.StatEndValue
          );
          this.formCaptureVisitors.StatStartValue = this.numberFormatter(
            data.StatStartValue
          );
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Page Views
    getPageViewCount() {
      let getPageViewCountParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post("/ws/Dashboard/GetPageViewCount", getPageViewCountParams)
        .then(response => {
          let data = response.data;

          this.pageViews.StatName = data.StatName;
          this.pageViews.StatChangePercent = data.StatChangePercent;
          this.pageViews.StatEndValue = this.numberFormatter(data.StatEndValue);
          this.pageViews.StatStartValue = this.numberFormatter(
            data.StatStartValue
          );
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Latest Visitors
    getLastestVisitors() {
      let getLastestVisitorsParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post("/ws/Dashboard/GetLastestVisitors", getLastestVisitorsParams)
        .then(response => {
          let data = response.data;

          this.latestVisitors = data;
          for (var i = 0; i < data.length; i++) {
            this.markers.push({
              position: {
                lat: parseFloat(data[i].Visitor_Company_Long),
                lng: parseFloat(data[i].Visitor_Company_Lat)
              },
              infoText:
                data[i].Visitor_Company_Name +
                " - " +
                data[i].Visitor_Company_City +
                ", " +
                data[i].Visitor_Company_State +
                " " +
                data[i].Visitor_Company_Country
            });
          }
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Site Traffic Overview
    getPageViewsUniqueVisitorsCompaniesIdentifiedByDay() {
      let getPageViewsUniqueVisitorsCompaniesIdentifiedByDayParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/Dashboard/GetPageViewsUniqueVisitorsCompaniesIdentifiedByDay",
          getPageViewsUniqueVisitorsCompaniesIdentifiedByDayParams
        )
        .then(response => {
          let data = response.data;
          let x = [];
          let FirstGroup = [];
          let SecondGroup = [];
          let ThirdGroup = [];

          data.forEach(data => {
            x.push(data.x);

            FirstGroup.push(data.Companies_Identified);
            SecondGroup.push(data.Unique_Visitors);
            ThirdGroup.push(data.Page_Views);
          });

          this.siteTrafficOverviewChartSeriesDataOne = FirstGroup
          this.siteTrafficOverviewChartSeriesDataTwo = SecondGroup
          this.siteTrafficOverviewChartSeriesDataThree = ThirdGroup
          this.siteTrafficOverviewChartOptionsXaxis = x

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Latest Keywords
    getLastestKeywords() {
      let getLastestKeywordsParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post("/ws/Dashboard/GetLastestKeywords", getLastestKeywordsParams)
        .then(response => {
          let data = response.data;

          this.latestKeywords = data;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Most Active Visiting Companies
    getTopCompanies() {
      let getTopCompaniesParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post("/ws/Dashboard/GetTopCompanies", getTopCompaniesParams)
        .then(response => {
          let data = response.data;
          let x = [];
          let FirstGroup = [];

          data.forEach(data => {
            x.push(data.Key);
            FirstGroup.push(data.Value);
          });

          if (!FirstGroup.length > 0) {
            FirstGroup = [0];
          }

          this.mostActiveVisitingCompaniesBarChartSeriesDataOne = FirstGroup
          this.mostActiveVisitingCompaniesBarChartOptionsXaxis = x

          // this.mostActiveVisitingCompaniesBarChart.series = [
          //   {
          //     name: "Views",
          //     data: FirstGroup
          //   }
          // ];

          // this.mostActiveVisitingCompaniesBarChart.chartOptions = {
          //   ...this.mostActiveVisitingCompaniesBarChart.chartOptions,
          //   ...{
          //     xaxis: {
          //       categories: x
          //     }
          //   }
          // };
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //get latest visitor popup details
    getSearchLeadDetail(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/SearchLead/GetSearchLeadDetail?id=" + id)
        .then(response => {
          let data = response.data;
          this.searchLeadDetails = data;

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //get latest visitor popup details
    getAllVisitorUniqueDetailShort(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/SearchLead/GetAllVisitorUniqueDetailShort?id=" + id)
        .then(response => {
          let data = response.data;
          this.allVisitorUniqueDetailShort = data;

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //get latest visitor popup details
    getPageViews(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/SearchLead/GetPageViews?id=" + id)
        .then(response => {
          let data = response.data;
          this.pageViewsCompany = data;

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //get latest visitor popup details
    getKeywords(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/SearchLead/GetKeywords?id=" + id)
        .then(response => {
          let data = response.data;
          this.keywords = data;

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    redirectToSignUp() {
      this.thankyouActive = false;
      this.$router.push({
        name: "sign-up",
        params: { uid: this.uid }
      });
    }
  }
};
</script>
